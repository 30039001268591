import { values } from 'mobx';
import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import Toasts from 'src/services/Toasts';
import { logRejectedModel } from 'src/utils/logger';

export enum InvoiceType {
    INVOICE = 'Invoice',
    CREDIT_MEMO = 'Credit Memo',
}

export const ParentBike = types.model({
    bom: types.string,
    code: types.string,
    description: types.string,
});

export type TInvoiceLine = typeof InvoiceLine.Type;

export const InvoiceLine = types
    .model('InvoiceLine', {
        id: types.string,
        description: types.string,
        code: types.string,
        shipmentDate: types.string,
        quantity: types.number,
        unitPrice: types.maybeNull(types.number),
        amountWithDiscount: types.maybeNull(types.number),
        discountAmount: types.maybeNull(types.number),
        discountPercentage: types.maybeNull(types.number),
        vatPercentage: types.maybeNull(types.number),
        totalAmountInclVAT: types.maybeNull(types.number),
        productType: types.string,
        serialNumberRequiredForReturn: types.optional(types.boolean, false),
        category: types.maybeNull(types.string),
        parentBikes: types.optional(types.array(ParentBike), []),
    })
    .actions(self => ({
        getParentBikes: flow(function* () {
            try {
                const getBikesByPartNumberResponse: any = yield Service.getBikesByPartNumber(self.code);

                if (getBikesByPartNumberResponse.status === 200) {
                    self.parentBikes = getBikesByPartNumberResponse.data || [];
                }
            } catch (error) {
                console.error(error);
            }
        }),
    }));

const Billing = types.model({
    city: types.string,
    contactCode: types.string,
    contactName: types.string,
    country: types.string,
    name: types.string,
    postalCode: types.string,
    state: types.string,
    street: types.string,
});

export type TInvoice = typeof Invoice.Type;

export const Invoice = types
    .model('Invoice', {
        invoiceId: types.identifier,
        name: types.string,
        orderCode: types.maybeNull(types.string),
        orderDate: types.maybeNull(types.string),
        totalAmountInclVAT: types.optional(types.number, 0),
        totalAmountExclVAT: types.optional(types.number, 0),
        reference: types.maybeNull(types.string),
        externalDocument: types.maybeNull(types.string),
        billing: types.maybeNull(Billing),
        createdDate: types.string,
        dueDate: types.string,
        paymentStatus: types.string,
        documentDate: types.string,
        paymentTermsDescription: types.string,
        remainingAmount: types.optional(types.number, 0),
        discountAmount: types.optional(types.number, 0),
        type: types.optional(types.enumeration(Object.values(InvoiceType)), InvoiceType.INVOICE),
        open: types.boolean,
        lines: types.optional(types.map(InvoiceLine), {}),
        downloadingPDF: types.optional(types.enumeration(['DOWNLOADING', 'SUCCESS', 'ERROR', 'IDLE']), 'IDLE'),
    })
    .volatile(self => ({
        returnInvoiceLineId: '',
        loading: false,
    }))
    .views(self => ({
        get getLinesArray(): TInvoiceLine[] {
            return (values(self.lines) as unknown) as TInvoiceLine[];
        },
        get getReturnInvoiceLine(): TInvoiceLine | null {
            const returnInvoiceLine = self.lines.get(self.returnInvoiceLineId);
            return returnInvoiceLine ? returnInvoiceLine : null;
        },
        get isDownloadingPDF() {
            return self.downloadingPDF === 'DOWNLOADING';
        },
        get hasNegativeAmount() {
            return self.totalAmountInclVAT <= 0;
        },
        get isCreditNote() {
            return self.type === InvoiceType.CREDIT_MEMO;
        },
    }))
    .actions(self => ({
        getInvoiceLines: flow(function* () {
            self.loading = true;
            try {
                const response: any = yield Service.getInvoiceLinesByInvoiceId(self.invoiceId);
                if (response && response.status === 200) {
                    response.data.forEach((invoiceLine: TInvoiceLine) => {
                        if (InvoiceLine.is(invoiceLine)) {
                            if (!self.lines.has(invoiceLine.id)) {
                                self.lines.set(invoiceLine.id, invoiceLine);
                                self.discountAmount = invoiceLine.discountAmount ? invoiceLine.discountAmount : 0;
                            }
                        } else {
                            logRejectedModel(InvoiceLine.name, invoiceLine);
                        }
                    });
                }
            } catch (err) {
                console.error(err);
            }

            self.loading = false;
        }),
        downloadPDF: flow(function* (lang: string | null) {
            self.downloadingPDF = 'DOWNLOADING';
            try {
                const response: any = yield Service.getInvoicePdfById(self.invoiceId, lang || 'NL');
                const url = window.URL.createObjectURL(response.data);
                // trick to make download work
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', `invoice-${self.invoiceId}.html`);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                self.downloadingPDF = 'IDLE';
            } catch (error) {
                self.downloadingPDF = 'ERROR';
                Toasts.ErrorDownloadPdf();
                console.error({ error });
            }
        }),
        setReturnInvoiceLineId: (returnInvoiceLineId: string) => {
            self.returnInvoiceLineId = returnInvoiceLineId;
        },
        setAsOverdue: () => {
            self.paymentStatus = 'Overdue';
        },
    }));
