import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import groupBy from 'lodash/groupBy';
import { values } from 'mobx';
import { flow, types } from 'mobx-state-tree';
import Toast from 'src/services/Toasts';
import { InvoiceWithSparePart } from 'src/types/commons/invoices';
import { logRejectedModel } from 'src/utils/logger';
import Service from '../services/api/Api';
import { Invoice, TInvoice } from './types/Invoice';

dayjs.extend(customParseFormat);

export const InvoiceBySparePart = types.model({
    invoiceId: Invoice.properties.invoiceId,
    documentDate: Invoice.properties.documentDate,
    name: typeof Invoice.properties.name,
    externalDocument: Invoice.properties.externalDocument,
});

export type TInvoiceBySparePart = typeof InvoiceBySparePart.Type;

const InvoicesStore = types
    .model('InvoicesStore', {
        list: types.optional(types.map(Invoice), {}),
        listBySparePart: types.optional(types.map(types.array(InvoiceBySparePart)), {}),
    })
    .volatile(() => ({
        loading: false,
        selectedInvoiceId: '',
    }))
    .views(self => ({
        getFilteredInvoices: (minDate: Date, maxDate: Date) => {
            const filtered = values(self.list).slice().reverse();
            if (minDate && maxDate) {
                return filtered.filter((invoice: any) => {
                    const createdDate = dayjs(invoice.createdDate, 'YYYY-MM-DD');
                    return (
                        (createdDate.isAfter(minDate) || createdDate.isSame(minDate)) &&
                        createdDate.isSameOrBefore(maxDate)
                    );
                });
            }
            return filtered;
        },
        get getSelectedInvoice() {
            return self.list.get(self.selectedInvoiceId);
        },
        getCheckedInvoices(selectedInvoiceIds: string[]) {
            return selectedInvoiceIds.map(selectedInvoice => {
                return self.list.get(selectedInvoice);
            });
        },
        get getAll() {
            return self.list;
        },
        getInvoiceByID: (id: string) => {
            return self.list.get(id);
        },
        getInvoiceIdByName: (invoiceName: string, sparePartId: string) => {
            // invoice.name is NAV invoice id starting with VF.. or VC..
            const selectedInvoice: any = values(self.list).find((invoice: any) => invoice.name === invoiceName);
            const selectedInvoiceBySparePart = self.listBySparePart
                .get(sparePartId)
                ?.find(invoice => invoice.name === invoiceName);
            return selectedInvoice?.invoiceId || selectedInvoiceBySparePart?.invoiceId || '';
        },

        getInvoiceListByPartId: (partId: string) => {
            const list = self.listBySparePart.get(partId);

            if (list) {
                return groupBy(list, 'name');
            }

            return undefined;
        },
    }))
    .actions(self => ({
        setSelectedInvoiceId: (selectedInvoiceId: string) => {
            self.selectedInvoiceId = selectedInvoiceId;
        },
        getInvoices: flow(function* (minDate: Date, maxDate: Date) {
            self.loading = true;
            try {
                const response: any = yield Service.getInvoicesByAccountId(
                    minDate.toISOString(),
                    maxDate.toISOString(),
                );

                if (response && response.status === 200) {
                    response.data.forEach((invoice: TInvoice) => {
                        if (Invoice.is(invoice)) {
                            self.list.set(invoice.invoiceId, invoice);
                        } else {
                            logRejectedModel(Invoice.name, invoice);
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
            self.loading = false;
        }),
        getInvoiceByInvoiceId: flow(function* (invoiceId: string) {
            self.loading = true;
            try {
                const response: any = yield Service.getInvoiceById(invoiceId);
                const invoice: TInvoice = response.data;
                if (response && response.status === 200 && invoice) {
                    if (Invoice.is(invoice)) {
                        self.list.set(invoice.invoiceId, invoice);
                    } else {
                        logRejectedModel(Invoice.name, invoice);
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),
        getInvoicesBySparePart: flow(function* (sparePartCode: string) {
            self.loading = true;
            try {
                const response: any = yield Service.getInvoicesBySparePart(sparePartCode);

                if (response && response.status === 200) {
                    // Pick only invoices with name starting with 'VF...'
                    const invoicesOnly =
                        response.data &&
                        response.data.filter((item: InvoiceWithSparePart) => /^VF\d+$/.test(item.name));
                    self.listBySparePart.clear();
                    if (invoicesOnly?.length) {
                        self.listBySparePart.set(sparePartCode, invoicesOnly);
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
            }
        }),

        // incoiceId can be SF invoiceId or NAV invoiceId starting with VF.. or VC..
        downloadPDFbyID: flow(function* (invoiceId: string, lang: string = 'nl-NL') {
            self.loading = true;

            try {
                const response: any = yield Service.getInvoicePdfById(invoiceId, lang);
                const url = window.URL.createObjectURL(response.data);
                // trick to make download work
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', `invoice-${invoiceId}.html`);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                self.loading = false;
            } catch (error) {
                console.error({ error });
                self.loading = false;
                Toast.ErrorDownloadPdf();
            }
        }),
    }));

export default InvoicesStore;
